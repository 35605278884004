<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="./assets/logo.png"
            transition="scale-transition"
            width="80"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <v-btn href="https://contravel.com.mx/" target="_blank" text>
        <span class="mr-2">ir a Contravel</span>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <section>
      <router-view />
    </section>
    <v-footer
      color="primary"
      dark
      class="bg-indigo-lighten-1 text-center d-flex flex-column"
    >
      <div class="pt-0">
        Somos una Consolidadora para Agencias de Viajes con más de 11 años de
        experiencia. Ofrecemos todo tipo de servicios de viajes por aire, mar y
        tierra para el beneficio de nuestros clientes con excelentes esquemas de
        pago y atractivas comisiones.
      </div>
      <br />
      <div>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          color="#B3E5FC"
          class="mx-4"
          variant="text"
        >
          <v-icon color="black">{{ icon }}</v-icon></v-btn
        >
      </div>
      <br />
      <div>{{ new Date().getFullYear() }} — <b>Contravel MX</b></div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>
