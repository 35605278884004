<template>
  <div>
    <b-loading is-full-page v-model="isLoading"> </b-loading>
    <div class="box">
      <b-tabs type="is-boxed">
        <b-tab-item label="Hoteles" icon="city-variant">
          <div class="box">
            <b-field>
              <b-radio-button
                v-model="radioButton"
                native-value="Ciudad"
                type="is-primary is-light is-outlined"
                @input="CargarData()"
              >
                Ciudad
              </b-radio-button>
              <b-radio-button
                @input="CargarData()"
                v-model="radioButton"
                native-value="Hotel"
                type="is-success is-light is-outlined"
              >
                Hotel
              </b-radio-button>
            </b-field>
            <div class="columns">
              <div class="column">
                <b-autocomplete
                  v-model="name"
                  :data="filteredDataArray"
                  placeholder="Ingresa la ciudad"
                  :field="NameField"
                  icon="magnify"
                  clearable
                  @select="(option) => obtenerCodes(option)"
                >
                  <template #empty>No results found</template>
                  <template slot-scope="props">
                    <div class="media">
                      <div v-if="radioButton == 'Ciudad'" class="media-content">
                        {{ props.option.Name }}
                        <br />
                      </div>

                      <div v-else class="media-content">
                        {{ props.option.HotelName }}
                        <br />
                      </div>
                    </div>
                  </template>
                </b-autocomplete>
              </div>
              <div class="column">
                <div class="columns">
                  <div class="column">
                    <b-field label="CheckInt" label-position="on-border">
                      <b-datepicker
                        placeholder="CheckInt"
                        v-model="dataFecha"
                        icon-right-clickable
                        icon="calendar-today"
                        trap-focus
                        required
                        ref="fieldDataFecha"
                        @input="ExtraerFechas(1)"
                      >
                      </b-datepicker
                    ></b-field>
                  </div>
                  <div class="column">
                    <b-field label="CheckOut" label-position="on-border">
                      <b-datepicker
                        placeholder="CheckOut"
                        v-model="dataFecha2"
                        icon-right-clickable
                        icon="calendar-today"
                        trap-focus
                        required
                        ref="fieldDataFecha"
                        @input="ExtraerFechas(2)"
                      >
                      </b-datepicker
                    ></b-field>
                  </div>
                </div>
              </div>
              <div class="column">
                <b-collapse :open="false" aria-id="contentIdForA11y1">
                  <template #trigger="props">
                    <b-button
                      label="
                        Habitaciones
                      "
                      type="is-primary"
                      aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open"
                    />
                  </template>
                  <div class="box">
                    <div class="columns">
                      <div class="column">
                        <b-field
                          label="Habitaciones"
                          label-position="on-border"
                        >
                          <b-select
                            expanded
                            v-model="Habitaciones"
                            placeholder="Selecciona uno"
                            @input="formarData(Habitaciones)"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </b-select>
                        </b-field>
                      </div>
                    </div>

                    <div v-if="Habitaciones">
                      <div
                        class="box"
                        v-for="hotel in HabitacionesData"
                        :key="hotel.id"
                      >
                        {{ hotel.id }}.- Habitación
                        <div class="rows">
                          <div class="row">
                            <br />
                            <div class="columns">
                              <div class="column">
                                <b-field
                                  label="Adultos"
                                  label-position="on-border"
                                >
                                  <b-select
                                    expanded
                                    v-model="hotel.adultos"
                                    placeholder="Selecciona uno"
                                    @input="
                                      IngresarAdultos(hotel.id, hotel.adultos)
                                    "
                                  >
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                  </b-select>
                                </b-field>
                              </div>
                              <div class="column">
                                <b-field
                                  label="Niños"
                                  label-position="on-border"
                                >
                                  <b-select
                                    expanded
                                    v-model="hotel.ninoslenght"
                                    placeholder="Selecciona uno"
                                    @input="
                                      IngresarNinios(
                                        hotel.id,
                                        hotel.ninoslenght
                                      )
                                    "
                                  >
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                  </b-select></b-field
                                >
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="columns"
                              v-for="ninio in hotel.ninios"
                              :key="ninio.id"
                            >
                              <div class="column">{{ ninio.id }}-. Niño</div>
                              <div class="column">
                                <b-field
                                  label="Edad"
                                  label-position="on-border"
                                >
                                  <b-select
                                    expanded
                                    v-model="ninio.edad"
                                    placeholder="Selecciona uno"
                                  >
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                  </b-select></b-field
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="column">
                <b-button
                  label="Search"
                  type="is-info"
                  @click="SearchHoteles"
                />
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Aereo" icon="airplane"></b-tab-item>
        <b-tab-item label="Autos" icon="car"></b-tab-item>
        <b-tab-item label="Cruceros" icon="ferry"></b-tab-item>
      </b-tabs>
    </div>

    <div class="box" v-if="visible == true && radioButton == 'Ciudad'">
      <Search :mi-array="miArray" />
    </div>

    <div class="box" v-if="visible == true && radioButton == 'Hotel'">
      <SearchHotel :mi-array="miArrayHotel" />
    </div>
  </div>
</template>

<script>
import HotelesService from "@/services/HotelesService";
import SearchHotel from "../components/SearchHotel.vue";
import Search from "../components/Search.vue";
export default {
  data() {
    return {
      radioButton: "Ciudad",
      miArrayHotel: new Object(),
      miArray: [],
      arrayHotel: [],
      dataFecha: null,
      dataFecha2: null,
      DataCityList: [],
      ArrayDHoteles: [],
      DataHotelList: null,
      name: "",
      selected: null,
      HabitacionesData: [],
      Habitaciones: [],
      FechasIyE: {},
      NumHabi: null,
      isLoading: false,
      NameField: "",
      visible: false,
    };
  },

  mounted() {
    this.CargarData();
  },
  computed: {
    filteredDataArray() {
      if (this.radioButton == "Ciudad") {
        return this.DataCityList.filter((option) => {
          return (
            option.Name.toString()
              .toLowerCase()
              .indexOf(this.name.toLowerCase()) >= 0
          );
        });
      } else {
        return this.ArrayDHoteles.filter((option) => {
          return (
            option.HotelName.toString()
              .toLowerCase()
              .indexOf(this.name.toLowerCase()) >= 0 && this.name.length >= 3
          );
        });
      }
    },
  },
  methods: {
    async SearchHoteles() {
      try {
        if (this.radioButton == "Hotel") {
          //Hotel Elcano//1146594 //Hotel Kin Ha Playa
          this.miArrayHotel = await HotelesService.obtenerDisponibilidad(
            this.arrayHotel,
            this.FechasIyE,
            this.HabitacionesData
          );
          console.log(this.miArrayHotel);
          this.visible = true;
        } else {
          this.visible = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    CargarData() {
      if (this.radioButton == "Ciudad") {
        this.obtenerCityList();
      } else {
        this.obtenerHotelList();
      }
    },

    IngresarNinios(hotelId, ninios) {
      let positionArray = hotelId - 1;
      this.HabitacionesData[positionArray].ninios = [];
      for (let index = 0; index < ninios; index++) {
        let niniosData = new Object();
        niniosData.id = index + 1;
        niniosData.edad = null;
        this.HabitacionesData[positionArray].ninios.push(niniosData);
      }
    },
    formarData(Habitaciones) {
      this.HabitacionesData = [];
      for (let index = 0; index < Habitaciones; index++) {
        let habitacionObject = new Object();
        habitacionObject.id = index + 1;
        habitacionObject.adultos = null;
        habitacionObject.ninios = null;
        habitacionObject.ninoslenght = null;
        this.HabitacionesData.push(habitacionObject);
      }
    },

    async obtenerCityList() {
      this.ArrayDHoteles = [];
      this.NameField = "Name";
      this.isLoading = true;
      this.DataCityList = await HotelesService.obtenerCityList();
      this.isLoading = false;
    },

    async obtenerHotelList() {
      this.DataCityList = [];
      this.NameField = "HotelName";
      this.isLoading = true;
      this.ArrayDHoteles = await HotelesService.obtenerHotelList();
      console.log(this.ArrayDHoteles);
      this.isLoading = false;
    },

    async obtenerCodes(option) {
      this.miArray = [];
      this.arrayHotel = [];
      if (this.radioButton == "Ciudad") {
        if (option != null) {
          this.isLoading = true;
          const codigoHoteles = await HotelesService.obtenerCodes(option.Name);
          console.log(codigoHoteles);
          this.isLoading = false;
          if (codigoHoteles) {
            this.miArray = codigoHoteles;
          }
        } else {
          console.log("No hay un destino que buscar" + option);
        }
      } else {
        this.arrayHotel = option;
      }
    },

    IngresarAdultos(id, adultos) {
      let NumAdul = parseInt(adultos);
      console.log(id, NumAdul);
    },

    ExtraerFechas(op) {
      let anioE = null;
      let mesE = null;
      let diaE = null;
      let fecha = null;
      if (op == 1) {
        anioE = this.dataFecha.getFullYear();
        mesE = this.dataFecha.getMonth() + 1;
        diaE = this.dataFecha.getDate();
        fecha = anioE + "-" + mesE + "-" + diaE;
        this.FechasIyE.CheckIn = fecha;
      } else {
        anioE = this.dataFecha2.getFullYear();
        mesE = this.dataFecha2.getMonth() + 1;
        diaE = this.dataFecha2.getDate();
        fecha = anioE + "-" + mesE + "-" + diaE;
        this.FechasIyE.CheckOut = fecha;
      }
    },
  },
  components: {
    Search,
    SearchHotel,
  },
};
</script>
