<template>
  <section>
    <b-steps v-model="activeStep" animated rounded has-navigation>
      <template #navigation="{ previous, next }">
        <b-step-item step="1" label="Resultados" type="is-info">
          <div class="rows">
            <h1 class="title has-text-centered">
              {{ miArray.Status.Description }}
            </h1>
            <h3 class="title has-text-centered">
              {{ miArray.HotelResult[0].Currency }}
            </h3>

            <div
              class="row"
              v-for="(item, index) in miArray.HotelResult"
              :key="index"
            >
              {{ item.HotelCode }}
              <b-table
                :data="isEmpty ? [] : miArray.HotelResult[index].Rooms"
                bordered
                striped
                narrowed
                hoverable
                focusable
                mobile-cards
                paginated
                per-page="10"
              >
                <b-table-column label="Name" v-slot="props">
                  {{ props.row.Name[0] }}
                </b-table-column>
                <b-table-column label="Inclusion" v-slot="props">
                  {{ props.row.Inclusion }}
                </b-table-column>

                <b-table-column label="RoomPromotion" v-slot="props">
                  {{ props.row.RoomPromotion[0] }}
                </b-table-column>
                <b-table-column
                  label="Supplements"
                  v-slot="props"
                  v-if="item[0]"
                >
                  <div
                    v-for="(item, index) in props.row.Supplements"
                    :key="index"
                  >
                    <div class="columns">
                      <div class="column">{{ item[0].Type }}</div>
                      <div class="column">{{ item[0].Description }}</div>
                      <div class="column">{{ item[0].Price }}</div>
                      <div class="column">{{ item[0].Currency }}</div>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column label="TotalFare" v-slot="props">
                  {{ props.row.TotalFare }}
                </b-table-column>
                <b-table-column label="TotalTax" v-slot="props">
                  {{ props.row.TotalTax }}
                </b-table-column>

                <b-table-column label="WithTransfers" v-slot="props">
                  <b-icon
                    v-if="props.row.WithTransfers"
                    type="is-success"
                    icon="check-circle"
                  ></b-icon>
                  <b-icon v-else type="is-danger" icon="close-circle"></b-icon>
                </b-table-column>
                <b-table-column label="Reembolsable" v-slot="props">
                  <b-icon
                    v-if="props.row.IsRefundable"
                    type="is-success"
                    icon="check-circle"
                  ></b-icon>
                  <b-icon v-else type="is-danger" icon="close-circle"></b-icon>
                </b-table-column>
                <b-table-column label="Continuar" v-slot="props">
                  <b-button
                    type="is-success"
                    icon-right="check-circle"
                    @click="continuar(props.row)"
                    @click.prevent="next.action"
                    rounded
                  />
                </b-table-column>
                <template #empty>
                  <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </div>
          </div>
        </b-step-item>

        <b-step-item step="2" :label="Hotel" type="is-success">
          <div class="notification" v-if="objPrebook">
            <div class="content">
              <h3>PaymentMode: {{ metodo }}</h3>
              <p>Booking: {{ Booking }}</p>
              Currency: {{ objPrebook.HotelResult[0].Currency }}
              <br />

              <div v-if="objPrebook.HotelResult[0].RateConditions.length > 0">
                <b-collapse
                  class="card"
                  animation="slide"
                  aria-id="contentIdForA11y3"
                  :open="false"
                >
                  <template #trigger="props">
                    <button
                      class="card-header"
                      aria-controls="contentIdForA11y3"
                      :aria-expanded="props.open"
                      @click="toggleCollapse"
                    >
                      <p class="card-header-title">RateConditions:</p>
                      <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                      </a>
                    </button>
                  </template>

                  <div class="card-content">
                    <div
                      class="content"
                      v-for="(valor, index) in objPrebook.HotelResult[0]
                        .RateConditions"
                      :key="index"
                    >
                      {{ index + 1 }}.-
                      <div
                        v-html="decodeEntities(valor)"
                        style="text-align: justify"
                      ></div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </b-step-item>

        <b-step-item step="3" label="Finish" disabled type="is-warning">
          <h1 class="title has-text-centered">Finish</h1>
          Lorem ipsum dolor sit amet.
        </b-step-item>
        <br />
        <b-button
          outlined
          type="is-danger"
          icon-left="skip-previous"
          :disabled="previous.disabled"
          @click.prevent="previous.action"
        >
          Previous
        </b-button>
        <b-button
          outlined
          type="is-success"
          icon-right="skip-next"
          :disabled="next.disabled"
          @click.prevent="next.action"
        >
          Next
        </b-button>
      </template>
    </b-steps>
  </section>
</template>

<script>
import HotelesService from "@/services/HotelesService";
import he from "he";

export default {
  props: {
    miArray: Object,
  },
  data() {
    return {
      isEmpty: false,
      activeStep: 0,
      Hotel: null,
      nextIcon: "chevron-right",
      metodo: null,
      Booking: null,
      objPrebook: undefined,
    };
  },
  mounted() {},
  methods: {
    decodeEntities(text) {
      return he.decode(text);
    },
    continuar(data) {
      this.Hotel = data.Name[0];
      this.Booking = data.BookingCode;
      this.metodo = "Limit";
      this.Prebook();
    },
    async Prebook() {
      //Hotel Elcano//1146594 //Hotel Kin Ha Playa
      this.objPrebook = await HotelesService.Prebook(this.Booking, this.metodo);
      console.log("Prebook: ");
      console.log(this.objPrebook);
    },
  },
};
</script>
