import HttpService from "@/services/HttpService";

const HotelesService = {
  async obtenerDato(dato) {
    return await HttpService.post("/apiGet.php", dato);
  },

  async MandarDato(dato) {
    return await HttpService.post("/apiPost.php", dato);
  },
  async obtenerCityList() {
    return await HttpService.get("/obtenerCityList.php");
  },
  async obtenerCountryList() {
    return await HttpService.get("/obtenerCountryList.php");
  },
  async obtenerHotelList() {
    return await HttpService.get("/obtenerHotelList.php");
  },
  async obtenerCodes(Dato) {
    const cargaUtil = { Dato };
    return await HttpService.post("/obtenerCodes.php", cargaUtil);
  },
  async obtenerDetallesHoteles(Dato) {
    const cargaUtil = { Dato };
    return await HttpService.post("/obtenerDetallesHoteles.php", cargaUtil);
  },

  async Prebook(Booking, pago) {
    const cargaUtil = { Booking, pago };
    return await HttpService.post("/obtenerPrebook.php", cargaUtil);
  },
  async obtenerDisponibilidad(
    arrayHotel,
    FechasIyE,
    HabitacionesData,
    Nacionality
  ) {
    const cargaUtil = { arrayHotel, FechasIyE, HabitacionesData, Nacionality };
    return await HttpService.post("/Search.php", cargaUtil);
  },
  async book(bookObject) {
    const cargaUtil = { bookObject };
    return await HttpService.post("/Book.php", cargaUtil);
  },
  async bookingDetail(bookObject) {
    return await HttpService.post("/BookingDetail.php", bookObject);
  },
  async guardarBookin(datos1, datos2) {
    const cargaUtil = { datos1, datos2 };
    return await HttpService.post("/SaveBooking.php", cargaUtil);
  },
  async Cancelar(bookObject) {
    return await HttpService.post("/Cancelar.php", bookObject);
  },
};
export default HotelesService;
