import Vue from "vue";
import "@mdi/font/css/materialdesignicons.css";
import App from "./App.vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import * as VueGoogleMaps from "vue2-google-maps/dist/main.js";
import router from "./router";
import agregarFiltros from "./filters";
import axios from "axios";
import Cookies from "js-cookie";
import vuetify from './plugins/vuetify'

agregarFiltros(Vue);

Vue.config.productionTip = false;
Vue.use(Buefy);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCPlLgGrT8AR7DT8g8L_MFb3DB9AKxn4UI",
    libraries: "places",
  },
});
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
Cookies.set("SID", "valor-de-la-cookie", { sameSite: "none", secure: true });
