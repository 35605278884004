<template>
  <v-app>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Buscando Hoteles disponibles
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-parallax src="../assets/cancun.jpg" class="backParalax" height="920">
      <div>
        <v-row>
          <v-col>
            <b-autocomplete
              v-model="name"
              :data="filteredDataArray"
              placeholder="Ingresa la ciudad"
              :field="NameField"
              icon="google-maps"
              clearable
              @select="(option) => obtenerCodes(option)"
            >
              <template #empty>No results found</template>
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.Name }}
                    <br />
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </v-col>
          <v-col>
            <b-datepicker
              placeholder="CheckInt"
              v-model="dataFecha"
              icon-right-clickable
              icon="calendar-today"
              trap-focus
              required
              ref="fieldDataFecha"
              @input="ExtraerFechas()"
              :min-date="MinFecha"
              :icon-right="dataFecha ? 'close-circle' : ''"
              @icon-right-click="clearDate(1)"
              :open-on-select="true"
              expanded
            >
            </b-datepicker>
          </v-col>
          <v-col>
            <b-datepicker
              placeholder="CheckOut"
              v-model="dataFecha2"
              icon-right-clickable
              icon="calendar-today"
              trap-focus
              required
              ref="fieldDataFecha2"
              @input="ExtraerFechas()"
              :icon-right="dataFecha2 ? 'close-circle' : ''"
              @icon-right-click="clearDate(2)"
              :min-date="dataFecha"
              expanded
            >
            </b-datepicker>
          </v-col>
          <v-col sm="2">
            <v-select
              v-model="select"
              :hint="`Nacionalidad: ${select.Name}, ${select.Code}`"
              :items="CountryList"
              item-text="Name"
              item-value="Code"
              label="Nacionalidad"
              persistent-hint
              return-object
              single-line
              solo
            ></v-select>
          </v-col>
          <v-col>
            <v-dialog transition="dialog-top-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-bed</v-icon> &nbsp;Habitaciones
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>
                    <div class="text-h6 pa-10">Rooms Data</div>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="Habitaciones"
                          label="Habitaciones (1-5)"
                          type="number"
                          min="1"
                          max="5"
                          hint="Ingresa el número de habitaciones"
                          :rules="[rules.required, rules.max]"
                          @input="formarData(Habitaciones)"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row justify="center">
                      <v-expansion-panels multiple v-model="panel">
                        <v-expansion-panel
                          v-for="hotel in HabitacionesData"
                          :key="hotel.id"
                        >
                          <v-expansion-panel-header>
                            <v-row>
                              <v-col> {{ hotel.id }}.- Habitación &nbsp;</v-col>
                              <v-col v-if="hotel.adultos"
                                >Adultos: {{ hotel.adultos }}</v-col
                              >
                              <v-col v-if="hotel.ninoslenght">
                                Niños: {{ hotel.ninoslenght }}</v-col
                              >
                            </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  v-model="hotel.adultos"
                                  label="Adultos (1-5)"
                                  type="number"
                                  min="1"
                                  max="5"
                                  hint="Ingresa el número de adultos"
                                  :rules="[rules.required, rules.max]"
                                ></v-text-field>
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-model="hotel.ninoslenght"
                                  label="Niños (1-4)"
                                  type="number"
                                  min="0"
                                  max="4"
                                  hint="Ingresa el número de niños"
                                  :rules="[ruleschild.required, ruleschild.max]"
                                  @input="
                                    IngresarNinios(hotel.id, hotel.ninoslenght)
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col v-if="hotel.ninoslenght > 0">
                                <v-row
                                  v-for="ninio in hotel.ninios"
                                  :key="ninio.id"
                                >
                                  <v-col>{{ ninio.id }}-. Niño</v-col>
                                  <v-col>
                                    <v-text-field
                                      v-model="ninio.edad"
                                      label="Edad"
                                      type="number"
                                      min="0"
                                      max="17"
                                      hint="Ingresa la edad del menor"
                                      :rules="[
                                        rulesNinios.required,
                                        rulesNinios.max,
                                      ]"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>
                    <v-divider></v-divider>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="SearchHoteles((dialog.value = false))"
                      >Continuar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col v-if="HabitacionesData.length > 0">
            <b-button
              :loading="loading"
              label="Search"
              type="is-info"
              @click="SearchHoteles"
              icon-left="magnify"
            />
          </v-col>
        </v-row>
      </div>
    </v-parallax>
    <v-row>
      <v-col v-for="n in 9" :key="n" class="d-flex child-flex" cols="4">
        <v-img
          :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
          :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
          aspect-ratio="1"
          cover
          class="bg-grey-lighten-2"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey-lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import HotelesService from "@/services/HotelesService";

export default {
  name: "TBOProjectInicio",

  data() {
    return {
      rules: {
        required: (value) => !!value || "Campo requerido",
        max: (value) => value <= 5 || "No puedes elegir más de 5",
      },
      ruleschild: {
        required: (value) => !!value || "Campo requerido",
        max: (value) => value <= 4 || "No puedes elegir más de 4",
      },
      rulesNinios: {
        required: (value) => !!value || "Campo requerido",
        max: (value) => value <= 17 || "La edad debe ser menor a los 18 años",
      },
      panel: [],
      loading: false,
      MinFecha: new Date(),
      arrayDeHoteles: [],
      miArray: [],
      arrayHotel: [],
      dataFecha: null,
      dataFecha2: null,
      DataCityList: [],
      CountryList: [],
      name: "",
      HabitacionesData: [],
      Habitaciones: null,
      FechasIyE: {},
      isLoading: false,
      NameField: "",
      arrayText: undefined,
      dialog: false,
      select: { Name: "México", Code: "MX" },
      City: null,
    };
  },

  mounted() {
    this.obtenerCityList();
    this.obtenerCountryList();
  },
  computed: {
    filteredDataArray() {
      return this.DataCityList
        ? this.DataCityList.filter((option) => {
            return (
              option &&
              option.Name &&
              option.Name.toString()
                .toLowerCase()
                .indexOf(this.name.toLowerCase()) >= 0
            );
          })
        : [];
    },
  },
  methods: {
    async SearchHoteles() {
      try {
        this.loading = true;
        const responseObject = {
          Status: null,
          HotelResult: [],
        };

        for (const element of this.miArray) {
          const hotelesresponse = await HotelesService.obtenerDisponibilidad(
            element,
            this.FechasIyE,
            this.HabitacionesData,
            this.select.Code
          );

          if (hotelesresponse.Status.Code === 200) {
            responseObject.HotelResult.push(...hotelesresponse.HotelResult);
            responseObject.Status = hotelesresponse.Status;
          } else {
            throw new Error(hotelesresponse.Status.Description);
          }
        }

        // Validar que los datos no estén vacíos o nulos
        if (
          this.HabitacionesData.length === 0 ||
          responseObject.HotelResult.length === 0
        ) {
          this.$buefy.toast.open({
            message:
              "No se encontraron resultados, ingrese una Ciudad diferente",
            type: "is-danger",
          });
          location.reload();
        }

        // Si los datos no están vacíos, avanzar a la siguiente página
        this.$router.push({
          name: "HotelesDisponibles",
          params: {
            arrayDeHoteles: responseObject.HotelResult,
            habitacionDescrip: this.HabitacionesData,
          },
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || "Hubo un error al buscar hoteles",
          type: "is-danger",
        });
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    formarData(Habitaciones) {
      if (Habitaciones > 5) {
        return;
      }
      this.HabitacionesData = [];

      for (let index = 0; index < Habitaciones; index++) {
        let habitacionObject = new Object();
        habitacionObject.id = index + 1;
        habitacionObject.adultos = 1;
        habitacionObject.ninoslenght = 0;
        habitacionObject.ninios = [];

        this.HabitacionesData.push(habitacionObject);
      }
      this.panel = [...Array(Habitaciones).keys()].map((k, i) => i);
    },

    IngresarNinios(hotelId, ninios) {
      let positionArray = hotelId - 1;
      this.HabitacionesData[positionArray].ninios = [];

      if (ninios > 4) {
        return;
      }
      for (let index = 0; index < ninios; index++) {
        let niniosData = new Object();
        niniosData.id = index + 1;
        niniosData.edad = 0;
        this.HabitacionesData[positionArray].ninios.push(niniosData);
      }
    },
    clearDate(op) {
      if (op == 1) {
        this.dataFecha = null;
      } else {
        this.dataFecha2 = null;
      }
    },
    async obtenerCityList() {
      try {
        this.NameField = "Name";
        this.isLoading = true;
        this.DataCityList = await HotelesService.obtenerCityList();

        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching city list:", error);
      }
    },

    async obtenerCountryList() {
      try {
        this.isLoading = true;
        this.CountryList = await HotelesService.obtenerCountryList();
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching country list:", error);
      }
    },
    async obtenerCodes(option) {
      if (option && option.Name) {
        this.City = option.Name;
        this.miArray = [];

        if (this.City != null) {
          this.isLoading = true;
          const codigoHoteles = await HotelesService.obtenerCodes(option.Name);
          this.isLoading = false;

          if (codigoHoteles) {
            const bloqueActual = [];
            let contador = 0;

            codigoHoteles.forEach((codigo) => {
              bloqueActual.push(codigo.Codes);
              contador++;

              if (
                contador === 100 ||
                codigo === codigoHoteles[codigoHoteles.length - 1]
              ) {
                this.miArray.push(bloqueActual.join(","));
                bloqueActual.length = 0;
                contador = 0;
              }
            });
          }
        } else {
          console.log("No hay un destino que buscar" + option);
        }
      } else {
        console.log(
          "La opción seleccionada es nula o no tiene una propiedad Name"
        );
      }
    },

    ExtraerFechas() {
      if (this.dataFecha2 == null) {
        this.dataFecha2 = new Date(this.dataFecha);
        this.dataFecha2.setDate(this.dataFecha.getDate() + 1);
      }
      if (this.dataFecha2 != null && this.dataFecha == null) {
        this.dataFecha = this.dataFecha2;
      }

      let anioE = null;
      let mesE = null;
      let diaE = null;
      let fecha = null;

      let anioE2 = null;
      let mesE2 = null;
      let diaE2 = null;
      let fecha2 = null;

      anioE = this.dataFecha.getFullYear();
      mesE = this.dataFecha.getMonth() + 1;
      diaE = this.dataFecha.getDate();
      fecha = anioE + "-" + mesE + "-" + diaE;
      this.FechasIyE.CheckIn = fecha;

      anioE2 = this.dataFecha2.getFullYear();
      mesE2 = this.dataFecha2.getMonth() + 1;
      diaE2 = this.dataFecha2.getDate();
      fecha2 = anioE2 + "-" + mesE2 + "-" + diaE2;
      this.FechasIyE.CheckOut = fecha2;
    },
  },
};
</script>
<style scoped>
.backParalax {
  max-height: 100%;
  bottom: 8%;
  max-width: 100%;
}
</style>